<template>
    <v-card
    v-if="loggedIn"
    class="mx-auto mt-6"
        >    
        <v-toolbar
            ligth
            cards                
            flat
            color="green lighten-4"
        >
            <v-card-title class="text-h6 font-weight-regular">
            Felder
            </v-card-title>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                                color="primary"
                                dark
                                fab
                                v-bind="attrs"
                                v-on="on"
                                class="mt-10"
                                @click="showAllFields"
                            >
                                <v-icon dark>
                                    mdi-map-marker-multiple
                                </v-icon>
                            </v-btn>
                    </template>
                    <span>Zeige Felder<br> in Karte</span>                           
                </v-tooltip>                    
                <v-spacer> </v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            dark
                            fab
                            v-bind="attrs"
                            v-on="on"
                            class="mt-10 mx-5"
                            @click="importFields"
                        >
                            <v-icon dark>
                                mdi-database-import-outline
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Felder von FIONA <br> importieren</span>                           
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                                color="primary"
                                dark
                                fab
                                v-bind="attrs"
                                v-on="on"
                                class="mt-10"
                                @click="addItem"
                            >
                                <v-icon dark>
                                    mdi-playlist-plus
                                </v-icon>
                            </v-btn>
                    </template>
                    <span>Felder hinzufügen</span>                           
                </v-tooltip>
            <v-dialog v-model="ctrlSFIM.isVisisble" width="800">
                <ctrlShowFielsInMap 
                    :fields="ctrlSFIM.fields"
                    :options="ctrlSFIM.options" 
                    :isVisible.sync="ctrlSFIM.isVisisble">
                </ctrlShowFielsInMap>
            </v-dialog>
            <v-dialog v-model="ctrlConfirmDel.isVisisble" :width="ctrlConfirmDel.width">
                <ctrlConfirmDel 
                    ref="ctrlConfirmDel" 
                    :text= "ctrlConfirmDel.text"
                    :delName="ctrlConfirmDel.delName"
                    @deleteItemConfirm="deleteItemConfirm"
                    @close="closeConfirmDel">
                </ctrlConfirmDel>              
            </v-dialog>   
            <v-dialog v-model="ctrlAEF.isVisisble" :width="ctrlAEF.width" >
                <ctrlAddEditField 
                    ref="ctrlAEF" 
                    :field="ctrlAEF.field"
                    :options="ctrlAEF.options"
                    :isVisible.sync="ctrlAEF.isVisisble"
                    @close="ctrlAEF.isVisisble = false"
                >                        
                </ctrlAddEditField>              
            </v-dialog>  
            <v-dialog v-model="ctrlIF.isVisisble" :width="ctrlIF.width" >
                <ctrlImportFields 
                    ref="ctrlImportFields"
                    :isVisible.sync="ctrlIF.isVisisble"
                    @close="ctrlIF.isVisisble = false">
                </ctrlImportFields>
            </v-dialog>              
        </v-toolbar>
        <v-card-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
            </v-card-title>
            <v-data-table
            ref="datatable"
            :headers="showHeaders"
            :items="items"
            :search="search"
            >

                <template v-slot:item.name="{ item }">
                    <router-link :to="{ name: 'Field', params: {uid:company.uid, fieldid: item.id} }">
                        {{ item.name }}
                    </router-link>
                </template>
                <template v-slot:item.enabled="{ item }">
                        <v-switch
                        v-model="item.enabled"
                        :disabled="(!access.edit_accept || item.roleLevel > user.roleLevel) && user.id != item.id"
                        @change="enabledChanged(item)"
                    ></v-switch>
                </template>
                <template v-slot:item.actions="{ item }">
                <v-icon    
                    :disabled="(!access.edit_accept || item.roleLevel > user.roleLevel) && user.id != item.id"
                    class="mr-2"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    :disabled="!access.delete_accept || item.roleLevel > user.roleLevel"
                    class="mr-2"
                    @click="deleteItem(item)"
                >
                    mdi-delete
                </v-icon>
                </template>               
                <template  v-slot:item.map="{ item }">
                    <v-icon
                        v-if="item.center"
                        :disabled="!access.view_accept"
                        large
                    class="mr-2 ml-n5"
                        @click="showDirection(item)"
                    >
                        mdi-sign-direction
                    </v-icon>

                    <v-icon
                        v-if="item.polygon"
                        :disabled="!access.view_accept"
                        class="mr-2"
                        large
                        @click="showMap(item)"
                    >
                        mdi-crosshairs-gps
                    </v-icon>
                </template>
            </v-data-table>

    </v-card>

</template>


<script>
export default {

   components: {
        ctrlShowFielsInMap: () => import('../../components/ctrl/fieldsShowInMap.vue'),
        ctrlAddEditField: () => import('../../components/ctrl/addEditField.vue'),
        ctrlConfirmDel: () => import('../../components/ctrl/confirmDeleteItem.vue'),
        ctrlImportFields: () => import('../../components/ctrl/fieldImport.vue'),
    },  

    data () {
        return {
            name: "FieldsViewer",
            compid:'',

            ctrlSFIM:{
                isVisisble: false,
                fields:{},
                options:{
                    title: 'Felder',
                    zoom: 12,
                    path:{
                        editable: false,
                        deletable: false,
                    }
                }

            },
            ctrlAEF:{
                isVisisble: false,
                width: 900,
                field:{},
                options:{
                    title: 'Feld',   
                    center: undefined,
                    isEdit: false
                }

            },
            ctrlConfirmDel:{
                width: 500,
                isVisisble: false,
                text: "Wollen Sie das Feld wirklich löschen?" ,
                delName: '',
                item: undefined
            },
            ctrlIF:{
                width: 700,
                isVisisble: false, 
                options:{
                    title: 'Import Fields',  
                }
            },            


            search: '',
            headers: [
                // { text: 'Num', align: 'start',sortable: false, value: 'index',},
                { text: 'Actions', value: 'actions', sortable: false },
                { text: 'Name', align: 'start',value: 'name',},
                { text: 'Anbauart', value: 'cultivation_species_caption' },
                { text: 'Fläche/ha', value: 'area_ha' },
                { text: 'Map',sortable: false, value: 'map' },
                { text: 'Aktiv', value: 'enabled' },
    
            ],  
            // items: [],
 
        }
    },

  
    computed: {

        showHeaders: function(){

            return this.headers;
        },

        loggedIn: function() {

            return this.$store.state.auth.status.loggedIn
            
        },
 
        user(){
            return this.$store.state.auth.user
        },

        access(){
            return this.$store.state.app.access
        },

        company() {
            return this.$store.state.app.actCompany.meta
        },

        fields(){
            return this.$store.state.app.fields

        },

        items(){
            return this.fields.items
        },

        refresh(){
            return this.$store.state.app.refresh
        }


        
    },
    methods:{


        showAllFields(){
            console.debug('showAllFields', this.fields);
            this.ctrlSFIM.options.title = 'Felder';
            this.ctrlSFIM.options.path.editable = false;
            this.ctrlSFIM.options.zoom = 14;
            this.ctrlSFIM.fields = this.fields;
            this.ctrlSFIM.isVisisble = !this.ctrlSFIM.isVisisble;
 
        },

        showMap(item){
            console.debug('showMap', item);
            this.ctrlSFIM.options.title = item.name;
            this.ctrlSFIM.options.path.editable = false;
            this.ctrlSFIM.options.zoom = 17;
            this.ctrlSFIM.fields = item;
            this.ctrlSFIM.isVisisble = true;
        },

        showDirection(item){
            console.debug('showDirection', item);
            const url = "https://www.google.com/maps/dir/?api=1&destination=" + item.center.lat + ","+ item.center.lng
            window.open(url, '_blank').focus();
        },


        addItem () {
            console.debug('addItem');
            this.ctrlAEF.options.isEdit = false;
                       setTimeout(() => {
                this.ctrlAEF.isVisisble = !this.ctrlAEF.isVisisble;
            }, 100);
        },

        editItem (item) {
            console.debug('editItem',item);
            this.ctrlAEF.options.isEdit = true;
            this.ctrlAEF.field = item;
            setTimeout(() => {
                this.ctrlAEF.isVisisble = !this.ctrlAEF.isVisisble;
            }, 100);
        },

        deleteItem (item) {
            console.debug('deleteItem', item);
            this.ctrlConfirmDel.delName = item.name
            this.ctrlConfirmDel.isVisisble = true;
            this.ctrlConfirmDel.item = item;
        },

        deleteItemConfirm () {
            console.debug('deleteItemConfirm');
            // this.$store.dispatch("app/deleteApiSetStore", {mutation:"deleteField" , path:"api/company/field", param:{compid:this.company.id, 'id':this.ctrlConfirmDel.item.id}})
            this.$store.dispatch("app/deleteApiSetStore", {path:"api/company/field", param:{store:"fields/items", compid:this.company.id, 'id':this.ctrlConfirmDel.item.id}})
            .then((response)=>{ 

                console.log("Field deleted:", response)
                this.ctrlConfirmDel.isVisisble = false; 

            })
            .catch((error)=>{
                console.error(error);
            })

        },

        closeConfirmDel(){
             console.debug('closeConfirmDel');
             this.ctrlConfirmDel.isVisisble = false;        
        },

        enabledChanged(item){
            console.debug('enabledChanged', item);
            this.$store.dispatch("app/putApiSetStore", {path:"api/company/field", param:{store:"fields/items", compid:this.company.id, data:item}})
            .then((response)=>{ 

                console.log("enabledChanged:", response)

            })
            .catch((error)=>{
                console.error(error);
            })

 
        },

        submit(item) {
            console.debug('submit', item);
        },
        close () {
            console.debug('close');
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },


        importFields(){
            console.debug('importFields');
            this.ctrlIF.isVisisble = ! this.ctrlIF.isVisisble

        },

        refreshContent(){
            console.debug('refreshContent');

            this.$store.dispatch("app/getApiSetStore", {path:"api/company/access", param:{store:"access", id:this.company.id, content:"fields"}
            }).then( ()=>{ 
                return this.$store.dispatch("app/getApiSetStore", {path:"api/company/fields/cultivationspecies", param:{store:"availableCultivationSpecies", id:this.company.id}})
            }).then( ()=>{ 
                return this.$store.dispatch("app/getApiSetStore", {path:"api/company/vinicultures/types", param:{store:"availableVinicultureTypes", id:this.company.id}})
            }).then( ()=>{    
               return this.$store.dispatch("app/getApiSetStore", {path:"api/company/fields", param:{store:"fields", id:this.company.id}})
            }).then( ()=>{    
                    console.debug('sdsdsd')  
            }).catch((error)=>{
                console.error(error);
            })

            
            // this.$store.dispatch("getFields")


        },
    },

    mounted() {

        console.debug('mounted'); 
        this.refreshContent();

    },

    watch: { 
        fields(newVal) { // watch it
            console.debug('Prop changed: fields', newVal);    
            // this.items.fields = newVal
        },



       items(newVal) { // watch it
            console.debug('Prop changed: items', newVal);    
            // this.items.fields = newVal
        },          
        company(newVal) { // watch it
            console.debug('Prop changed: company', newVal);    
            this.refreshContent();
        }    
    }      

}
</script>